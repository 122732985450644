const Header = () => {
  return String.raw`
        <header>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="user-select: none; cursor: pointer;" class="menu"><path d="M 5 8 L 26 8" fill="transparent" stroke-width="3" stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round" style="transform-origin: 15px 8px 0px; transform: none;"></path><path d="M 5 15 L 26 15" fill="transparent" stroke-width="3" stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round" style="opacity: 1;"></path><path d="M 5 22 L 26 22" fill="transparent" stroke-width="3" stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round" style="transform-origin: 15px 22px 0px; transform: none;"></path></svg>
        <div class="container">
            <div class="logo y-g">
                <a href="index.html"><img width="35" height="25" src="./assets/images/webkyat-logo.png" width="100"
                        alt="webkyat logo">
                    <p>webkyat</p>
                </a>
            </div>
            <nav>
                <ul>
                    <li>
                        <a href="./index.html" class="active">Home</a>
                    </li>
                    <li>
                        <a href="./about.html">About</a>
                    </li>
                    <li>
                        <a href="./services.html">Services</a>
                    </li>
                    <li>
                        <a href="./projects.html">Projects</a>
                    </li>
                    <li>
                        <a href="./contact.html">Contact</a>
                    </li>

                </ul>
            </nav>
            <div class="hangburger y-g">
                <span></span>
                <span></span>
                <div class="progressor-round">

                </div>
            </div>
            <div class="social">
                <ul>
                    <li>
                        <a href="">
                            <ion-icon name="logo-facebook"></ion-icon>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <ion-icon name="logo-twitter"></ion-icon>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <ion-icon name="logo-dribbble"></ion-icon>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </header>
    `;
};
export default Header;