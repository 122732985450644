const Footer = () => {
  return String.raw`
    <div class="container">
      <footer>
        <div class="layer">
          <img
            class="footer-logo"
            src="./assets/images/webkyat-logo-outline.png"
            alt="" />
          <div class="footer-head">
            <div class="left">
              <h2>Have project on mind ?</h2>
            </div>
            <div class="right">
              <div class="content">
                <div class="c-left">
                  <div class="icon">
                    <img
                      src="./assets/images/talkwith_kannan_uthaman.png"
                      alt="" />
                  </div>
                  <div class="txt">
                    <h3>
                      Hello,<br />
                      Kannan Uthaman
                    </h3>
                    <span>Ready to assist you anytime</span>
                  </div>
                </div>
                <div class="c-right">
                  <a href="./contact.html" class="cta-primary">Let's Talk</a>
                </div>
              </div>
            </div>
          </div>

          <div class="footer-body">
            <div class="splits"><span class="name">Webkyat</span></div>
            <div class="splits">
              <h3>Quick Links</h3>
              <ul>
                <li><a href="./index.html">Home</a></li>
                <li><a href="./services.html">Service</a></li>
                <li><a href="./projects.html">Projects</a></li>
              </ul>
            </div>
            <div class="splits">
              <h3>About Company</h3>
              <ul>
                <li><a href="./about.html">About</a></li>
                <li><a href="./team.html">Team</a></li>
                <li><a href="./career.html">Career</a></li>
              </ul>
            </div>
            <div class="splits">
              <h3>Other Information</h3>
              <ul>
                <li><a href="./privacy-policy.html">Privay policy</a></li>
                <li><a href="./terms-condition.html">Terms & Condtion</a></li>
                <li><a href="./faq.html">Faq</a></li>
              </ul>
              <ul class="social">
                <li>
                  <a href="https://www.instagram.com/webkyat/">
                    <img src="./assets/images/instagram_icon.png" alt="instagram logo" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/webkyat">
                    <img style="transform : scale(0.7)" src="./assets/icons/xlogo.png" alt="xlogo" />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/Webkyat/">
                  <img style="" src="./assets/icons/facebook.png" alt="facebook" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="footer-end">© 2023 Webkyat. All Rights Reserved.</div>
      </footer>
    </div>
    `;
};
export default Footer;