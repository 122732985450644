import barba from "../_snowpack/pkg/@barba/core.js";
window.barba = barba;
import "./fn/loader.js";
import "../test.js";
import "../style/App.css";
import { basic } from "./fn/Basic.js";
import { gsap } from "../_snowpack/pkg/gsap.js";
import anime from "../_snowpack/pkg/animejs.js";
import emailjs from "../_snowpack/pkg/@emailjs/browser.js";
// logo redirection
$(".logo").click(function () {
  barba.go("index.html");
});

// // nav animation
const frame = $(".float-nav");
const activer = $(".activer");
const movement = (elem, duration) => {
  const x = elem.offset().left - frame.offset().left - 5;
  const frameWidth = frame.width();
  const percentage = x / frameWidth * 100;
  activer.css({
    left: Number(percentage) + "%",
    transition: "0.8s"
  });
};
activer.css({
  width: $(".float-nav li a").width()
});
let clickedLink = null;
$(".float-nav li a").on("click", function (e) {
  e.preventDefault();
  clickedLink = $(this);
  // movement($(this));
});

const setActiverByDefault = () => {
  let pageRef = $("main").attr("data-page-ref");
  $(".float-nav li a").each(function () {
    const text = $(this).text().toLowerCase();
    if (text == pageRef) {
      // $(".activer").show();
      movement($(this), 0);
      clickedLink = $(this);
    } else {
      // $(".activer").hide();
    }
  });
};
$(".float-nav li a").on("mouseover", function (event) {
  movement($(this), 0.5);
});
$(".float-nav").on("mouseleave", function (event) {
  if (clickedLink) {
    movement(clickedLink, 0.5);
  } else {}
});
barba.hooks.after(() => {
  setActiverByDefault();
});
setActiverByDefault();
const swipeup = document.querySelector(".swipeup");
const loader = gsap.timeline();
barba.init({
  debug: true,
  transitions: [{
    name: "default-transition",
    async leave(data) {
      return new Promise(resolve => {
        // create your stunning leave animation here
        loader.set(swipeup, {
          autoAlpha: 1,
          attr: {
            d: "M 0 1 V 1 Q 0.5 1 1 1 V 1 z"
          } //bottom line
        });

        loader.to(swipeup, {
          duration: 0.2,
          ease: "power4.in",
          attr: {
            d: "M 0 1 V 0.5 Q 0.5 0 1 0.5 V 1 z"
          }
        });
        loader.to(swipeup, {
          duration: 0.6,
          ease: "power2",
          attr: {
            d: "M 0 1 V 0 Q 0.5 0 1 0 V 1 z"
          },
          //full square
          onComplete: resolve
        });
      });
    },
    async enter(data) {
      return new Promise(resolve => {
        loader.set(swipeup, {
          onComplete: resolve
        });
        loader.to(swipeup, {
          duration: 0.2,
          ease: "power4.in",
          attr: {
            d: "M 0 1 V 0.5 Q 0.5 1 1 0.5 V 1 z"
          },
          //arc dip
          onComplete: resolve
        });
        loader.to(swipeup, {
          duration: 0.2,
          ease: "power2",
          attr: {
            d: "M 0 1 V 1 Q 0.5 1 1 1 V 1 z"
          } //bottom line final
        });

        loader.from("main", {
          autoAlpha: 0,
          y: 80,
          duration: 1
        }, "-=0.3");
      });
    }
  }],
  views: [{
    namespace: "home",
    beforeEnter(data) {
      basic();
      import("./fn/Home.js").then(x => {
        x.Home();
      });
    }
  }, {
    namespace: "contact",
    beforeEnter(data) {
      basic();
      const form = document.getElementById("form");
      const button = document.querySelector(".send-btn");
      const greeting = document.querySelector(".greetings");
      form.addEventListener("submit", e => {
        e.preventDefault();
        button.textContent = "Sending..";
        let templateParams = {
          to_name: "Webkyat",
          from_name: form.querySelector("#name").value,
          phone: form.querySelector("#phone").value
        };
        emailjs.send("service_9hujm9h", "template_el3mpkf", templateParams, "kLGE-E_kVmYJmxiZS").then(response => {
          button.textContent = "Submit";
          greeting.style.display = "block";
          form.reset();
          setTimeout(() => {
            greeting.style.display = "none";
          }, 5000);
        }, err => {
          button.textContent = "Submit";
          console.log("FAILED...", err);
        });
      });
      // do send email
      // const btn = document.querySelector(".send-btn");
      // document
      //   .getElementById("form")
      //   .addEventListener("submit", function (event) {
      //     event.preventDefault();
      //     alert("hi");
      //     btn.value = "Sending...";
      //     const serviceID = "service_9hujm9h";
      //     const templateID = "template_el3mpkf";

      //     emailjs.sendForm(serviceID, templateID, this).then(
      //       () => {
      //         btn.value = "Submit";
      //         alert("Sent!");
      //       },
      //       (err) => {
      //         btn.value = "Submit";
      //         alert(JSON.stringify(err));
      //       }
      //     );
      //   });
      // do whatsapp
      $(".do_whatsapp").on("click", function () {
        location.href = "https://api.whatsapp.com/send?phone=+919746197164&text=Hello Webkyat";
      });
    }
  }, {
    namespace: "service",
    beforeEnter(data) {
      basic();
    }
  }, {
    namespace: "Projects",
    beforeEnter(data) {
      basic();

      // double click like
      $(".Projects-holder").dblclick(function () {
        const loverPopup = $(this).find(".lover-popup");
        const loverHolder = $(this).find(".love");
        let audio = new Audio("./assets/audio/tapsound.mp3");
        audio.play();
        let tl = anime.timeline();
        tl.add({
          targets: loverPopup[0],
          opacity: 1,
          scale: 3,
          duration: 1000
        });
        tl.add({
          targets: loverPopup[0],
          opacity: 0,
          scale: 1
        });
        loverHolder.empty();
        loverHolder.append(`<ion-icon name="heart"></ion-icon>`);
        anime({
          targets: loverHolder.find("ion-icon")[0],
          scale: [0, 1],
          duration: 1000
        }, "-=2000");
      });
    }
  }, {
    namespace: "about",
    beforeEnter(data) {
      basic();
      import("./fn/About.js").then(x => {
        x.About();
      });
    }
  }, {
    namespace: "privacy",
    beforeEnter(data) {
      basic();
      import("./fn/About.js").then(x => {
        x.About();
      });
    }
  }]
});