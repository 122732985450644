function t(t, n) {
  for (var r = 0; r < n.length; r++) {
    var i = n[r];
    i.enumerable = i.enumerable || !1, i.configurable = !0, "value" in i && (i.writable = !0), Object.defineProperty(t, i.key, i);
  }
}
function n(n, r, i) {
  return r && t(n.prototype, r), i && t(n, i), n;
}
function r() {
  return (r = Object.assign || function (t) {
    for (var n = 1; n < arguments.length; n++) {
      var r = arguments[n];
      for (var i in r) Object.prototype.hasOwnProperty.call(r, i) && (t[i] = r[i]);
    }
    return t;
  }).apply(this, arguments);
}
function i(t, n) {
  t.prototype = Object.create(n.prototype), t.prototype.constructor = t, t.__proto__ = n;
}
function e(t) {
  return (e = Object.setPrototypeOf ? Object.getPrototypeOf : function (t) {
    return t.__proto__ || Object.getPrototypeOf(t);
  })(t);
}
function o(t, n) {
  return (o = Object.setPrototypeOf || function (t, n) {
    return t.__proto__ = n, t;
  })(t, n);
}
function u(t, n, r) {
  return (u = function () {
    if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
    if (Reflect.construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Date.prototype.toString.call(Reflect.construct(Date, [], function () {})), !0;
    } catch (t) {
      return !1;
    }
  }() ? Reflect.construct : function (t, n, r) {
    var i = [null];
    i.push.apply(i, n);
    var e = new (Function.bind.apply(t, i))();
    return r && o(e, r.prototype), e;
  }).apply(null, arguments);
}
function s(t) {
  var n = "function" == typeof Map ? new Map() : void 0;
  return (s = function (t) {
    if (null === t || -1 === Function.toString.call(t).indexOf("[native code]")) return t;
    if ("function" != typeof t) throw new TypeError("Super expression must either be null or a function");
    if (void 0 !== n) {
      if (n.has(t)) return n.get(t);
      n.set(t, r);
    }
    function r() {
      return u(t, arguments, e(this).constructor);
    }
    return r.prototype = Object.create(t.prototype, {
      constructor: {
        value: r,
        enumerable: !1,
        writable: !0,
        configurable: !0
      }
    }), o(r, t);
  })(t);
}
function f(t, n) {
  try {
    var r = t();
  } catch (t) {
    return n(t);
  }
  return r && r.then ? r.then(void 0, n) : r;
}
"undefined" != typeof Symbol && (Symbol.iterator || (Symbol.iterator = Symbol("Symbol.iterator"))), "undefined" != typeof Symbol && (Symbol.asyncIterator || (Symbol.asyncIterator = Symbol("Symbol.asyncIterator")));
var c,
  a = "2.9.7",
  h = function () {};
!function (t) {
  t[t.off = 0] = "off", t[t.error = 1] = "error", t[t.warning = 2] = "warning", t[t.info = 3] = "info", t[t.debug = 4] = "debug";
}(c || (c = {}));
var v = c.off,
  l = function () {
    function t(t) {
      this.t = t;
    }
    t.getLevel = function () {
      return v;
    }, t.setLevel = function (t) {
      return v = c[t];
    };
    var n = t.prototype;
    return n.error = function () {
      for (var t = arguments.length, n = new Array(t), r = 0; r < t; r++) n[r] = arguments[r];
      this.i(console.error, c.error, n);
    }, n.warn = function () {
      for (var t = arguments.length, n = new Array(t), r = 0; r < t; r++) n[r] = arguments[r];
      this.i(console.warn, c.warning, n);
    }, n.info = function () {
      for (var t = arguments.length, n = new Array(t), r = 0; r < t; r++) n[r] = arguments[r];
      this.i(console.info, c.info, n);
    }, n.debug = function () {
      for (var t = arguments.length, n = new Array(t), r = 0; r < t; r++) n[r] = arguments[r];
      this.i(console.log, c.debug, n);
    }, n.i = function (n, r, i) {
      r <= t.getLevel() && n.apply(console, ["[" + this.t + "] "].concat(i));
    }, t;
  }(),
  d = O,
  m = E,
  w = g,
  p = x,
  b = T,
  y = "/",
  P = new RegExp(["(\\\\.)", "(?:\\:(\\w+)(?:\\(((?:\\\\.|[^\\\\()])+)\\))?|\\(((?:\\\\.|[^\\\\()])+)\\))([+*?])?"].join("|"), "g");
function g(t, n) {
  for (var r, i = [], e = 0, o = 0, u = "", s = n && n.delimiter || y, f = n && n.whitelist || void 0, c = !1; null !== (r = P.exec(t));) {
    var a = r[0],
      h = r[1],
      v = r.index;
    if (u += t.slice(o, v), o = v + a.length, h) u += h[1], c = !0;else {
      var l = "",
        d = r[2],
        m = r[3],
        w = r[4],
        p = r[5];
      if (!c && u.length) {
        var b = u.length - 1,
          g = u[b];
        (!f || f.indexOf(g) > -1) && (l = g, u = u.slice(0, b));
      }
      u && (i.push(u), u = "", c = !1);
      var E = m || w,
        x = l || s;
      i.push({
        name: d || e++,
        prefix: l,
        delimiter: x,
        optional: "?" === p || "*" === p,
        repeat: "+" === p || "*" === p,
        pattern: E ? A(E) : "[^" + k(x === s ? x : x + s) + "]+?"
      });
    }
  }
  return (u || o < t.length) && i.push(u + t.substr(o)), i;
}
function E(t, n) {
  return function (r, i) {
    var e = t.exec(r);
    if (!e) return !1;
    for (var o = e[0], u = e.index, s = {}, f = i && i.decode || decodeURIComponent, c = 1; c < e.length; c++) if (void 0 !== e[c]) {
      var a = n[c - 1];
      s[a.name] = a.repeat ? e[c].split(a.delimiter).map(function (t) {
        return f(t, a);
      }) : f(e[c], a);
    }
    return {
      path: o,
      index: u,
      params: s
    };
  };
}
function x(t, n) {
  for (var r = new Array(t.length), i = 0; i < t.length; i++) "object" == typeof t[i] && (r[i] = new RegExp("^(?:" + t[i].pattern + ")$", R(n)));
  return function (n, i) {
    for (var e = "", o = i && i.encode || encodeURIComponent, u = !i || !1 !== i.validate, s = 0; s < t.length; s++) {
      var f = t[s];
      if ("string" != typeof f) {
        var c,
          a = n ? n[f.name] : void 0;
        if (Array.isArray(a)) {
          if (!f.repeat) throw new TypeError('Expected "' + f.name + '" to not repeat, but got array');
          if (0 === a.length) {
            if (f.optional) continue;
            throw new TypeError('Expected "' + f.name + '" to not be empty');
          }
          for (var h = 0; h < a.length; h++) {
            if (c = o(a[h], f), u && !r[s].test(c)) throw new TypeError('Expected all "' + f.name + '" to match "' + f.pattern + '"');
            e += (0 === h ? f.prefix : f.delimiter) + c;
          }
        } else if ("string" != typeof a && "number" != typeof a && "boolean" != typeof a) {
          if (!f.optional) throw new TypeError('Expected "' + f.name + '" to be ' + (f.repeat ? "an array" : "a string"));
        } else {
          if (c = o(String(a), f), u && !r[s].test(c)) throw new TypeError('Expected "' + f.name + '" to match "' + f.pattern + '", but got "' + c + '"');
          e += f.prefix + c;
        }
      } else e += f;
    }
    return e;
  };
}
function k(t) {
  return t.replace(/([.+*?=^!:${}()[\]|/\\])/g, "\\$1");
}
function A(t) {
  return t.replace(/([=!:$/()])/g, "\\$1");
}
function R(t) {
  return t && t.sensitive ? "" : "i";
}
function T(t, n, r) {
  for (var i = (r = r || {}).strict, e = !1 !== r.start, o = !1 !== r.end, u = r.delimiter || y, s = [].concat(r.endsWith || []).map(k).concat("$").join("|"), f = e ? "^" : "", c = 0; c < t.length; c++) {
    var a = t[c];
    if ("string" == typeof a) f += k(a);else {
      var h = a.repeat ? "(?:" + a.pattern + ")(?:" + k(a.delimiter) + "(?:" + a.pattern + "))*" : a.pattern;
      n && n.push(a), f += a.optional ? a.prefix ? "(?:" + k(a.prefix) + "(" + h + "))?" : "(" + h + ")?" : k(a.prefix) + "(" + h + ")";
    }
  }
  if (o) i || (f += "(?:" + k(u) + ")?"), f += "$" === s ? "$" : "(?=" + s + ")";else {
    var v = t[t.length - 1],
      l = "string" == typeof v ? v[v.length - 1] === u : void 0 === v;
    i || (f += "(?:" + k(u) + "(?=" + s + "))?"), l || (f += "(?=" + k(u) + "|" + s + ")");
  }
  return new RegExp(f, R(r));
}
function O(t, n, r) {
  return t instanceof RegExp ? function (t, n) {
    if (!n) return t;
    var r = t.source.match(/\((?!\?)/g);
    if (r) for (var i = 0; i < r.length; i++) n.push({
      name: i,
      prefix: null,
      delimiter: null,
      optional: !1,
      repeat: !1,
      pattern: null
    });
    return t;
  }(t, n) : Array.isArray(t) ? function (t, n, r) {
    for (var i = [], e = 0; e < t.length; e++) i.push(O(t[e], n, r).source);
    return new RegExp("(?:" + i.join("|") + ")", R(r));
  }(t, n, r) : function (t, n, r) {
    return T(g(t, r), n, r);
  }(t, n, r);
}
d.match = function (t, n) {
  var r = [];
  return E(O(t, r, n), r);
}, d.regexpToFunction = m, d.parse = w, d.compile = function (t, n) {
  return x(g(t, n), n);
}, d.tokensToFunction = p, d.tokensToRegExp = b;
var S = {
    container: "container",
    history: "history",
    namespace: "namespace",
    prefix: "data-barba",
    prevent: "prevent",
    wrapper: "wrapper"
  },
  j = new (function () {
    function t() {
      this.o = S, this.u = new DOMParser();
    }
    var n = t.prototype;
    return n.toString = function (t) {
      return t.outerHTML;
    }, n.toDocument = function (t) {
      return this.u.parseFromString(t, "text/html");
    }, n.toElement = function (t) {
      var n = document.createElement("div");
      return n.innerHTML = t, n;
    }, n.getHtml = function (t) {
      return void 0 === t && (t = document), this.toString(t.documentElement);
    }, n.getWrapper = function (t) {
      return void 0 === t && (t = document), t.querySelector("[" + this.o.prefix + '="' + this.o.wrapper + '"]');
    }, n.getContainer = function (t) {
      return void 0 === t && (t = document), t.querySelector("[" + this.o.prefix + '="' + this.o.container + '"]');
    }, n.removeContainer = function (t) {
      document.body.contains(t) && t.parentNode.removeChild(t);
    }, n.addContainer = function (t, n) {
      var r = this.getContainer();
      r ? this.s(t, r) : n.appendChild(t);
    }, n.getNamespace = function (t) {
      void 0 === t && (t = document);
      var n = t.querySelector("[" + this.o.prefix + "-" + this.o.namespace + "]");
      return n ? n.getAttribute(this.o.prefix + "-" + this.o.namespace) : null;
    }, n.getHref = function (t) {
      if (t.tagName && "a" === t.tagName.toLowerCase()) {
        if ("string" == typeof t.href) return t.href;
        var n = t.getAttribute("href") || t.getAttribute("xlink:href");
        if (n) return this.resolveUrl(n.baseVal || n);
      }
      return null;
    }, n.resolveUrl = function () {
      for (var t = arguments.length, n = new Array(t), r = 0; r < t; r++) n[r] = arguments[r];
      var i = n.length;
      if (0 === i) throw new Error("resolveUrl requires at least one argument; got none.");
      var e = document.createElement("base");
      if (e.href = arguments[0], 1 === i) return e.href;
      var o = document.getElementsByTagName("head")[0];
      o.insertBefore(e, o.firstChild);
      for (var u, s = document.createElement("a"), f = 1; f < i; f++) s.href = arguments[f], e.href = u = s.href;
      return o.removeChild(e), u;
    }, n.s = function (t, n) {
      n.parentNode.insertBefore(t, n.nextSibling);
    }, t;
  }())(),
  M = new (function () {
    function t() {
      this.h = [], this.v = -1;
    }
    var i = t.prototype;
    return i.init = function (t, n) {
      this.l = "barba";
      var r = {
        ns: n,
        scroll: {
          x: window.scrollX,
          y: window.scrollY
        },
        url: t
      };
      this.h.push(r), this.v = 0;
      var i = {
        from: this.l,
        index: 0,
        states: [].concat(this.h)
      };
      window.history && window.history.replaceState(i, "", t);
    }, i.change = function (t, n, r) {
      if (r && r.state) {
        var i = r.state,
          e = i.index;
        n = this.m(this.v - e), this.replace(i.states), this.v = e;
      } else this.add(t, n);
      return n;
    }, i.add = function (t, n) {
      var r = this.size,
        i = this.p(n),
        e = {
          ns: "tmp",
          scroll: {
            x: window.scrollX,
            y: window.scrollY
          },
          url: t
        };
      this.h.push(e), this.v = r;
      var o = {
        from: this.l,
        index: r,
        states: [].concat(this.h)
      };
      switch (i) {
        case "push":
          window.history && window.history.pushState(o, "", t);
          break;
        case "replace":
          window.history && window.history.replaceState(o, "", t);
      }
    }, i.update = function (t, n) {
      var i = n || this.v,
        e = r({}, this.get(i), {}, t);
      this.set(i, e);
    }, i.remove = function (t) {
      t ? this.h.splice(t, 1) : this.h.pop(), this.v--;
    }, i.clear = function () {
      this.h = [], this.v = -1;
    }, i.replace = function (t) {
      this.h = t;
    }, i.get = function (t) {
      return this.h[t];
    }, i.set = function (t, n) {
      return this.h[t] = n;
    }, i.p = function (t) {
      var n = "push",
        r = t,
        i = S.prefix + "-" + S.history;
      return r.hasAttribute && r.hasAttribute(i) && (n = r.getAttribute(i)), n;
    }, i.m = function (t) {
      return Math.abs(t) > 1 ? t > 0 ? "forward" : "back" : 0 === t ? "popstate" : t > 0 ? "back" : "forward";
    }, n(t, [{
      key: "current",
      get: function () {
        return this.h[this.v];
      }
    }, {
      key: "state",
      get: function () {
        return this.h[this.h.length - 1];
      }
    }, {
      key: "previous",
      get: function () {
        return this.v < 1 ? null : this.h[this.v - 1];
      }
    }, {
      key: "size",
      get: function () {
        return this.h.length;
      }
    }]), t;
  }())(),
  L = function (t, n) {
    try {
      var r = function () {
        if (!n.next.html) return Promise.resolve(t).then(function (t) {
          var r = n.next;
          if (t) {
            var i = j.toElement(t);
            r.namespace = j.getNamespace(i), r.container = j.getContainer(i), r.html = t, M.update({
              ns: r.namespace
            });
            var e = j.toDocument(t);
            document.title = e.title;
          }
        });
      }();
      return Promise.resolve(r && r.then ? r.then(function () {}) : void 0);
    } catch (t) {
      return Promise.reject(t);
    }
  },
  $ = d,
  _ = {
    __proto__: null,
    update: L,
    nextTick: function () {
      return new Promise(function (t) {
        window.requestAnimationFrame(t);
      });
    },
    pathToRegexp: $
  },
  q = function () {
    return window.location.origin;
  },
  B = function (t) {
    return void 0 === t && (t = window.location.href), U(t).port;
  },
  U = function (t) {
    var n,
      r = t.match(/:\d+/);
    if (null === r) /^http/.test(t) && (n = 80), /^https/.test(t) && (n = 443);else {
      var i = r[0].substring(1);
      n = parseInt(i, 10);
    }
    var e,
      o = t.replace(q(), ""),
      u = {},
      s = o.indexOf("#");
    s >= 0 && (e = o.slice(s + 1), o = o.slice(0, s));
    var f = o.indexOf("?");
    return f >= 0 && (u = D(o.slice(f + 1)), o = o.slice(0, f)), {
      hash: e,
      path: o,
      port: n,
      query: u
    };
  },
  D = function (t) {
    return t.split("&").reduce(function (t, n) {
      var r = n.split("=");
      return t[r[0]] = r[1], t;
    }, {});
  },
  F = function (t) {
    return void 0 === t && (t = window.location.href), t.replace(/(\/#.*|\/|#.*)$/, "");
  },
  H = {
    __proto__: null,
    getHref: function () {
      return window.location.href;
    },
    getOrigin: q,
    getPort: B,
    getPath: function (t) {
      return void 0 === t && (t = window.location.href), U(t).path;
    },
    parse: U,
    parseQuery: D,
    clean: F
  };
function I(t, n, r) {
  return void 0 === n && (n = 2e3), new Promise(function (i, e) {
    var o = new XMLHttpRequest();
    o.onreadystatechange = function () {
      if (o.readyState === XMLHttpRequest.DONE) if (200 === o.status) i(o.responseText);else if (o.status) {
        var n = {
          status: o.status,
          statusText: o.statusText
        };
        r(t, n), e(n);
      }
    }, o.ontimeout = function () {
      var i = new Error("Timeout error [" + n + "]");
      r(t, i), e(i);
    }, o.onerror = function () {
      var n = new Error("Fetch error");
      r(t, n), e(n);
    }, o.open("GET", t), o.timeout = n, o.setRequestHeader("Accept", "text/html,application/xhtml+xml,application/xml"), o.setRequestHeader("x-barba", "yes"), o.send();
  });
}
var C = function (t) {
  return !!t && ("object" == typeof t || "function" == typeof t) && "function" == typeof t.then;
};
function N(t, n) {
  return void 0 === n && (n = {}), function () {
    for (var r = arguments.length, i = new Array(r), e = 0; e < r; e++) i[e] = arguments[e];
    var o = !1,
      u = new Promise(function (r, e) {
        n.async = function () {
          return o = !0, function (t, n) {
            t ? e(t) : r(n);
          };
        };
        var u = t.apply(n, i);
        o || (C(u) ? u.then(r, e) : r(u));
      });
    return u;
  };
}
var X = new (function (t) {
    function n() {
      var n;
      return (n = t.call(this) || this).logger = new l("@barba/core"), n.all = ["ready", "page", "reset", "currentAdded", "currentRemoved", "nextAdded", "nextRemoved", "beforeOnce", "once", "afterOnce", "before", "beforeLeave", "leave", "afterLeave", "beforeEnter", "enter", "afterEnter", "after"], n.registered = new Map(), n.init(), n;
    }
    i(n, t);
    var r = n.prototype;
    return r.init = function () {
      var t = this;
      this.registered.clear(), this.all.forEach(function (n) {
        t[n] || (t[n] = function (r, i) {
          t.registered.has(n) || t.registered.set(n, new Set()), t.registered.get(n).add({
            ctx: i || {},
            fn: r
          });
        });
      });
    }, r.do = function (t) {
      for (var n = this, r = arguments.length, i = new Array(r > 1 ? r - 1 : 0), e = 1; e < r; e++) i[e - 1] = arguments[e];
      if (this.registered.has(t)) {
        var o = Promise.resolve();
        return this.registered.get(t).forEach(function (t) {
          o = o.then(function () {
            return N(t.fn, t.ctx).apply(void 0, i);
          });
        }), o.catch(function (r) {
          n.logger.debug("Hook error [" + t + "]"), n.logger.error(r);
        });
      }
      return Promise.resolve();
    }, r.clear = function () {
      var t = this;
      this.all.forEach(function (n) {
        delete t[n];
      }), this.init();
    }, r.help = function () {
      this.logger.info("Available hooks: " + this.all.join(","));
      var t = [];
      this.registered.forEach(function (n, r) {
        return t.push(r);
      }), this.logger.info("Registered hooks: " + t.join(","));
    }, n;
  }(h))(),
  z = function () {
    function t(t) {
      if (this.P = [], "boolean" == typeof t) this.g = t;else {
        var n = Array.isArray(t) ? t : [t];
        this.P = n.map(function (t) {
          return $(t);
        });
      }
    }
    return t.prototype.checkHref = function (t) {
      if ("boolean" == typeof this.g) return this.g;
      var n = U(t).path;
      return this.P.some(function (t) {
        return null !== t.exec(n);
      });
    }, t;
  }(),
  G = function (t) {
    function n(n) {
      var r;
      return (r = t.call(this, n) || this).k = new Map(), r;
    }
    i(n, t);
    var e = n.prototype;
    return e.set = function (t, n, r) {
      return this.k.set(t, {
        action: r,
        request: n
      }), {
        action: r,
        request: n
      };
    }, e.get = function (t) {
      return this.k.get(t);
    }, e.getRequest = function (t) {
      return this.k.get(t).request;
    }, e.getAction = function (t) {
      return this.k.get(t).action;
    }, e.has = function (t) {
      return !this.checkHref(t) && this.k.has(t);
    }, e.delete = function (t) {
      return this.k.delete(t);
    }, e.update = function (t, n) {
      var i = r({}, this.k.get(t), {}, n);
      return this.k.set(t, i), i;
    }, n;
  }(z),
  Q = function () {
    return !window.history.pushState;
  },
  W = function (t) {
    return !t.el || !t.href;
  },
  J = function (t) {
    var n = t.event;
    return n.which > 1 || n.metaKey || n.ctrlKey || n.shiftKey || n.altKey;
  },
  K = function (t) {
    var n = t.el;
    return n.hasAttribute("target") && "_blank" === n.target;
  },
  V = function (t) {
    var n = t.el;
    return void 0 !== n.protocol && window.location.protocol !== n.protocol || void 0 !== n.hostname && window.location.hostname !== n.hostname;
  },
  Y = function (t) {
    var n = t.el;
    return void 0 !== n.port && B() !== B(n.href);
  },
  Z = function (t) {
    var n = t.el;
    return n.getAttribute && "string" == typeof n.getAttribute("download");
  },
  tt = function (t) {
    return t.el.hasAttribute(S.prefix + "-" + S.prevent);
  },
  nt = function (t) {
    return Boolean(t.el.closest("[" + S.prefix + "-" + S.prevent + '="all"]'));
  },
  rt = function (t) {
    var n = t.href;
    return F(n) === F() && B(n) === B();
  },
  it = function (t) {
    function n(n) {
      var r;
      return (r = t.call(this, n) || this).suite = [], r.tests = new Map(), r.init(), r;
    }
    i(n, t);
    var r = n.prototype;
    return r.init = function () {
      this.add("pushState", Q), this.add("exists", W), this.add("newTab", J), this.add("blank", K), this.add("corsDomain", V), this.add("corsPort", Y), this.add("download", Z), this.add("preventSelf", tt), this.add("preventAll", nt), this.add("sameUrl", rt, !1);
    }, r.add = function (t, n, r) {
      void 0 === r && (r = !0), this.tests.set(t, n), r && this.suite.push(t);
    }, r.run = function (t, n, r, i) {
      return this.tests.get(t)({
        el: n,
        event: r,
        href: i
      });
    }, r.checkLink = function (t, n, r) {
      var i = this;
      return this.suite.some(function (e) {
        return i.run(e, t, n, r);
      });
    }, n;
  }(z),
  et = function (t) {
    function n(r, i) {
      var e;
      void 0 === i && (i = "Barba error");
      for (var o = arguments.length, u = new Array(o > 2 ? o - 2 : 0), s = 2; s < o; s++) u[s - 2] = arguments[s];
      return (e = t.call.apply(t, [this].concat(u)) || this).error = r, e.label = i, Error.captureStackTrace && Error.captureStackTrace(function (t) {
        if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        return t;
      }(e), n), e.name = "BarbaError", e;
    }
    return i(n, t), n;
  }(s(Error)),
  ot = function () {
    function t(t) {
      void 0 === t && (t = []), this.logger = new l("@barba/core"), this.all = [], this.page = [], this.once = [], this.A = [{
        name: "namespace",
        type: "strings"
      }, {
        name: "custom",
        type: "function"
      }], t && (this.all = this.all.concat(t)), this.update();
    }
    var n = t.prototype;
    return n.add = function (t, n) {
      switch (t) {
        case "rule":
          this.A.splice(n.position || 0, 0, n.value);
          break;
        case "transition":
        default:
          this.all.push(n);
      }
      this.update();
    }, n.resolve = function (t, n) {
      var r = this;
      void 0 === n && (n = {});
      var i = n.once ? this.once : this.page;
      i = i.filter(n.self ? function (t) {
        return t.name && "self" === t.name;
      } : function (t) {
        return !t.name || "self" !== t.name;
      });
      var e = new Map(),
        o = i.find(function (i) {
          var o = !0,
            u = {};
          return !(!n.self || "self" !== i.name) || (r.A.reverse().forEach(function (n) {
            o && (o = r.R(i, n, t, u), i.from && i.to && (o = r.R(i, n, t, u, "from") && r.R(i, n, t, u, "to")), i.from && !i.to && (o = r.R(i, n, t, u, "from")), !i.from && i.to && (o = r.R(i, n, t, u, "to")));
          }), e.set(i, u), o);
        }),
        u = e.get(o),
        s = [];
      if (s.push(n.once ? "once" : "page"), n.self && s.push("self"), u) {
        var f,
          c = [o];
        Object.keys(u).length > 0 && c.push(u), (f = this.logger).info.apply(f, ["Transition found [" + s.join(",") + "]"].concat(c));
      } else this.logger.info("No transition found [" + s.join(",") + "]");
      return o;
    }, n.update = function () {
      var t = this;
      this.all = this.all.map(function (n) {
        return t.T(n);
      }).sort(function (t, n) {
        return t.priority - n.priority;
      }).reverse().map(function (t) {
        return delete t.priority, t;
      }), this.page = this.all.filter(function (t) {
        return void 0 !== t.leave || void 0 !== t.enter;
      }), this.once = this.all.filter(function (t) {
        return void 0 !== t.once;
      });
    }, n.R = function (t, n, r, i, e) {
      var o = !0,
        u = !1,
        s = t,
        f = n.name,
        c = f,
        a = f,
        h = f,
        v = e ? s[e] : s,
        l = "to" === e ? r.next : r.current;
      if (e ? v && v[f] : v[f]) {
        switch (n.type) {
          case "strings":
          default:
            var d = Array.isArray(v[c]) ? v[c] : [v[c]];
            l[c] && -1 !== d.indexOf(l[c]) && (u = !0), -1 === d.indexOf(l[c]) && (o = !1);
            break;
          case "object":
            var m = Array.isArray(v[a]) ? v[a] : [v[a]];
            l[a] ? (l[a].name && -1 !== m.indexOf(l[a].name) && (u = !0), -1 === m.indexOf(l[a].name) && (o = !1)) : o = !1;
            break;
          case "function":
            v[h](r) ? u = !0 : o = !1;
        }
        u && (e ? (i[e] = i[e] || {}, i[e][f] = s[e][f]) : i[f] = s[f]);
      }
      return o;
    }, n.O = function (t, n, r) {
      var i = 0;
      return (t[n] || t.from && t.from[n] || t.to && t.to[n]) && (i += Math.pow(10, r), t.from && t.from[n] && (i += 1), t.to && t.to[n] && (i += 2)), i;
    }, n.T = function (t) {
      var n = this;
      t.priority = 0;
      var r = 0;
      return this.A.forEach(function (i, e) {
        r += n.O(t, i.name, e + 1);
      }), t.priority = r, t;
    }, t;
  }(),
  ut = function () {
    function t(t) {
      void 0 === t && (t = []), this.logger = new l("@barba/core"), this.S = !1, this.store = new ot(t);
    }
    var r = t.prototype;
    return r.get = function (t, n) {
      return this.store.resolve(t, n);
    }, r.doOnce = function (t) {
      var n = t.data,
        r = t.transition;
      try {
        var i = function () {
            e.S = !1;
          },
          e = this,
          o = r || {};
        e.S = !0;
        var u = f(function () {
          return Promise.resolve(e.j("beforeOnce", n, o)).then(function () {
            return Promise.resolve(e.once(n, o)).then(function () {
              return Promise.resolve(e.j("afterOnce", n, o)).then(function () {});
            });
          });
        }, function (t) {
          e.S = !1, e.logger.debug("Transition error [before/after/once]"), e.logger.error(t);
        });
        return Promise.resolve(u && u.then ? u.then(i) : i());
      } catch (t) {
        return Promise.reject(t);
      }
    }, r.doPage = function (t) {
      var n = t.data,
        r = t.transition,
        i = t.page,
        e = t.wrapper;
      try {
        var o = function (t) {
            if (u) return t;
            s.S = !1;
          },
          u = !1,
          s = this,
          c = r || {},
          a = !0 === c.sync || !1;
        s.S = !0;
        var h = f(function () {
          function t() {
            return Promise.resolve(s.j("before", n, c)).then(function () {
              var t = !1;
              function r(r) {
                return t ? r : Promise.resolve(s.remove(n)).then(function () {
                  return Promise.resolve(s.j("after", n, c)).then(function () {});
                });
              }
              var o = function () {
                if (a) return f(function () {
                  return Promise.resolve(s.add(n, e)).then(function () {
                    return Promise.resolve(s.j("beforeLeave", n, c)).then(function () {
                      return Promise.resolve(s.j("beforeEnter", n, c)).then(function () {
                        return Promise.resolve(Promise.all([s.leave(n, c), s.enter(n, c)])).then(function () {
                          return Promise.resolve(s.j("afterLeave", n, c)).then(function () {
                            return Promise.resolve(s.j("afterEnter", n, c)).then(function () {});
                          });
                        });
                      });
                    });
                  });
                }, function (t) {
                  if (s.M(t)) throw new et(t, "Transition error [sync]");
                });
                var r = function (r) {
                    return t ? r : f(function () {
                      var t = function () {
                        if (!1 !== o) return Promise.resolve(s.add(n, e)).then(function () {
                          return Promise.resolve(s.j("beforeEnter", n, c)).then(function () {
                            return Promise.resolve(s.enter(n, c, o)).then(function () {
                              return Promise.resolve(s.j("afterEnter", n, c)).then(function () {});
                            });
                          });
                        });
                      }();
                      if (t && t.then) return t.then(function () {});
                    }, function (t) {
                      if (s.M(t)) throw new et(t, "Transition error [before/after/enter]");
                    });
                  },
                  o = !1,
                  u = f(function () {
                    return Promise.resolve(s.j("beforeLeave", n, c)).then(function () {
                      return Promise.resolve(Promise.all([s.leave(n, c), L(i, n)]).then(function (t) {
                        return t[0];
                      })).then(function (t) {
                        return o = t, Promise.resolve(s.j("afterLeave", n, c)).then(function () {});
                      });
                    });
                  }, function (t) {
                    if (s.M(t)) throw new et(t, "Transition error [before/after/leave]");
                  });
                return u && u.then ? u.then(r) : r(u);
              }();
              return o && o.then ? o.then(r) : r(o);
            });
          }
          var r = function () {
            if (a) return Promise.resolve(L(i, n)).then(function () {});
          }();
          return r && r.then ? r.then(t) : t();
        }, function (t) {
          if (s.S = !1, t.name && "BarbaError" === t.name) throw s.logger.debug(t.label), s.logger.error(t.error), t;
          throw s.logger.debug("Transition error [page]"), s.logger.error(t), t;
        });
        return Promise.resolve(h && h.then ? h.then(o) : o(h));
      } catch (t) {
        return Promise.reject(t);
      }
    }, r.once = function (t, n) {
      try {
        return Promise.resolve(X.do("once", t, n)).then(function () {
          return n.once ? N(n.once, n)(t) : Promise.resolve();
        });
      } catch (t) {
        return Promise.reject(t);
      }
    }, r.leave = function (t, n) {
      try {
        return Promise.resolve(X.do("leave", t, n)).then(function () {
          return n.leave ? N(n.leave, n)(t) : Promise.resolve();
        });
      } catch (t) {
        return Promise.reject(t);
      }
    }, r.enter = function (t, n, r) {
      try {
        return Promise.resolve(X.do("enter", t, n)).then(function () {
          return n.enter ? N(n.enter, n)(t, r) : Promise.resolve();
        });
      } catch (t) {
        return Promise.reject(t);
      }
    }, r.add = function (t, n) {
      try {
        return j.addContainer(t.next.container, n), X.do("nextAdded", t), Promise.resolve();
      } catch (t) {
        return Promise.reject(t);
      }
    }, r.remove = function (t) {
      try {
        return j.removeContainer(t.current.container), X.do("currentRemoved", t), Promise.resolve();
      } catch (t) {
        return Promise.reject(t);
      }
    }, r.M = function (t) {
      return t.message ? !/Timeout error|Fetch error/.test(t.message) : !t.status;
    }, r.j = function (t, n, r) {
      try {
        return Promise.resolve(X.do(t, n, r)).then(function () {
          return r[t] ? N(r[t], r)(n) : Promise.resolve();
        });
      } catch (t) {
        return Promise.reject(t);
      }
    }, n(t, [{
      key: "isRunning",
      get: function () {
        return this.S;
      },
      set: function (t) {
        this.S = t;
      }
    }, {
      key: "hasOnce",
      get: function () {
        return this.store.once.length > 0;
      }
    }, {
      key: "hasSelf",
      get: function () {
        return this.store.all.some(function (t) {
          return "self" === t.name;
        });
      }
    }, {
      key: "shouldWait",
      get: function () {
        return this.store.all.some(function (t) {
          return t.to && !t.to.route || t.sync;
        });
      }
    }]), t;
  }(),
  st = function () {
    function t(t) {
      var n = this;
      this.names = ["beforeLeave", "afterLeave", "beforeEnter", "afterEnter"], this.byNamespace = new Map(), 0 !== t.length && (t.forEach(function (t) {
        n.byNamespace.set(t.namespace, t);
      }), this.names.forEach(function (t) {
        X[t](n.L(t));
      }));
    }
    return t.prototype.L = function (t) {
      var n = this;
      return function (r) {
        var i = t.match(/enter/i) ? r.next : r.current,
          e = n.byNamespace.get(i.namespace);
        return e && e[t] ? N(e[t], e)(r) : Promise.resolve();
      };
    }, t;
  }();
Element.prototype.matches || (Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector), Element.prototype.closest || (Element.prototype.closest = function (t) {
  var n = this;
  do {
    if (n.matches(t)) return n;
    n = n.parentElement || n.parentNode;
  } while (null !== n && 1 === n.nodeType);
  return null;
});
var ft = {
    container: null,
    html: "",
    namespace: "",
    url: {
      hash: "",
      href: "",
      path: "",
      port: null,
      query: {}
    }
  },
  ct = new (function () {
    function t() {
      this.version = a, this.schemaPage = ft, this.Logger = l, this.logger = new l("@barba/core"), this.plugins = [], this.hooks = X, this.dom = j, this.helpers = _, this.history = M, this.request = I, this.url = H;
    }
    var i = t.prototype;
    return i.use = function (t, n) {
      var r = this.plugins;
      r.indexOf(t) > -1 ? this.logger.warn("Plugin [" + t.name + "] already installed.") : "function" == typeof t.install ? (t.install(this, n), r.push(t)) : this.logger.warn("Plugin [" + t.name + '] has no "install" method.');
    }, i.init = function (t) {
      var n = void 0 === t ? {} : t,
        i = n.transitions,
        e = void 0 === i ? [] : i,
        o = n.views,
        u = void 0 === o ? [] : o,
        s = n.schema,
        f = void 0 === s ? S : s,
        c = n.requestError,
        a = n.timeout,
        h = void 0 === a ? 2e3 : a,
        v = n.cacheIgnore,
        d = void 0 !== v && v,
        m = n.prefetchIgnore,
        w = void 0 !== m && m,
        p = n.preventRunning,
        b = void 0 !== p && p,
        y = n.prevent,
        P = void 0 === y ? null : y,
        g = n.debug,
        E = n.logLevel;
      if (l.setLevel(!0 === (void 0 !== g && g) ? "debug" : void 0 === E ? "off" : E), this.logger.info(this.version), Object.keys(f).forEach(function (t) {
        S[t] && (S[t] = f[t]);
      }), this.$ = c, this.timeout = h, this.cacheIgnore = d, this.prefetchIgnore = w, this.preventRunning = b, this._ = this.dom.getWrapper(), !this._) throw new Error("[@barba/core] No Barba wrapper found");
      this._.setAttribute("aria-live", "polite"), this.q();
      var x = this.data.current;
      if (!x.container) throw new Error("[@barba/core] No Barba container found");
      if (this.cache = new G(d), this.prevent = new it(w), this.transitions = new ut(e), this.views = new st(u), null !== P) {
        if ("function" != typeof P) throw new Error("[@barba/core] Prevent should be a function");
        this.prevent.add("preventCustom", P);
      }
      this.history.init(x.url.href, x.namespace), this.B = this.B.bind(this), this.U = this.U.bind(this), this.D = this.D.bind(this), this.F(), this.plugins.forEach(function (t) {
        return t.init();
      });
      var k = this.data;
      k.trigger = "barba", k.next = k.current, k.current = r({}, this.schemaPage), this.hooks.do("ready", k), this.once(k), this.q();
    }, i.destroy = function () {
      this.q(), this.H(), this.history.clear(), this.hooks.clear(), this.plugins = [];
    }, i.force = function (t) {
      window.location.assign(t);
    }, i.go = function (t, n, r) {
      var i;
      if (void 0 === n && (n = "barba"), this.transitions.isRunning) this.force(t);else if (!(i = "popstate" === n ? this.history.current && this.url.getPath(this.history.current.url) === this.url.getPath(t) : this.prevent.run("sameUrl", null, null, t)) || this.transitions.hasSelf) return n = this.history.change(t, n, r), r && (r.stopPropagation(), r.preventDefault()), this.page(t, n, i);
    }, i.once = function (t) {
      try {
        var n = this;
        return Promise.resolve(n.hooks.do("beforeEnter", t)).then(function () {
          function r() {
            return Promise.resolve(n.hooks.do("afterEnter", t)).then(function () {});
          }
          var i = function () {
            if (n.transitions.hasOnce) {
              var r = n.transitions.get(t, {
                once: !0
              });
              return Promise.resolve(n.transitions.doOnce({
                transition: r,
                data: t
              })).then(function () {});
            }
          }();
          return i && i.then ? i.then(r) : r();
        });
      } catch (t) {
        return Promise.reject(t);
      }
    }, i.page = function (t, n, i) {
      try {
        var e = function () {
            var t = o.data;
            return Promise.resolve(o.hooks.do("page", t)).then(function () {
              var n = f(function () {
                var n = o.transitions.get(t, {
                  once: !1,
                  self: i
                });
                return Promise.resolve(o.transitions.doPage({
                  data: t,
                  page: u,
                  transition: n,
                  wrapper: o._
                })).then(function () {
                  o.q();
                });
              }, function () {
                0 === l.getLevel() && o.force(t.current.url.href);
              });
              if (n && n.then) return n.then(function () {});
            });
          },
          o = this;
        o.data.next.url = r({
          href: t
        }, o.url.parse(t)), o.data.trigger = n;
        var u = o.cache.has(t) ? o.cache.update(t, {
            action: "click"
          }).request : o.cache.set(t, o.request(t, o.timeout, o.onRequestError.bind(o, n)), "click").request,
          s = function () {
            if (o.transitions.shouldWait) return Promise.resolve(L(u, o.data)).then(function () {});
          }();
        return Promise.resolve(s && s.then ? s.then(e) : e());
      } catch (t) {
        return Promise.reject(t);
      }
    }, i.onRequestError = function (t) {
      this.transitions.isRunning = !1;
      for (var n = arguments.length, r = new Array(n > 1 ? n - 1 : 0), i = 1; i < n; i++) r[i - 1] = arguments[i];
      var e = r[0],
        o = r[1],
        u = this.cache.getAction(e);
      return this.cache.delete(e), !(this.$ && !1 === this.$(t, u, e, o) || ("click" === u && this.force(e), 1));
    }, i.prefetch = function (t) {
      var n = this;
      this.cache.has(t) || this.cache.set(t, this.request(t, this.timeout, this.onRequestError.bind(this, "barba")).catch(function (t) {
        n.logger.error(t);
      }), "prefetch");
    }, i.F = function () {
      !0 !== this.prefetchIgnore && (document.addEventListener("mouseover", this.B), document.addEventListener("touchstart", this.B)), document.addEventListener("click", this.U), window.addEventListener("popstate", this.D);
    }, i.H = function () {
      !0 !== this.prefetchIgnore && (document.removeEventListener("mouseover", this.B), document.removeEventListener("touchstart", this.B)), document.removeEventListener("click", this.U), window.removeEventListener("popstate", this.D);
    }, i.B = function (t) {
      var n = this,
        r = this.I(t);
      if (r) {
        var i = this.dom.getHref(r);
        this.prevent.checkHref(i) || this.cache.has(i) || this.cache.set(i, this.request(i, this.timeout, this.onRequestError.bind(this, r)).catch(function (t) {
          n.logger.error(t);
        }), "enter");
      }
    }, i.U = function (t) {
      var n = this.I(t);
      if (n) return this.transitions.isRunning && this.preventRunning ? (t.preventDefault(), void t.stopPropagation()) : void this.go(this.dom.getHref(n), n, t);
    }, i.D = function (t) {
      this.go(this.url.getHref(), "popstate", t);
    }, i.I = function (t) {
      for (var n = t.target; n && !this.dom.getHref(n);) n = n.parentNode;
      if (n && !this.prevent.checkLink(n, t, this.dom.getHref(n))) return n;
    }, i.q = function () {
      var t = this.url.getHref(),
        n = {
          container: this.dom.getContainer(),
          html: this.dom.getHtml(),
          namespace: this.dom.getNamespace(),
          url: r({
            href: t
          }, this.url.parse(t))
        };
      this.C = {
        current: n,
        next: r({}, this.schemaPage),
        trigger: void 0
      }, this.hooks.do("reset", this.data);
    }, n(t, [{
      key: "data",
      get: function () {
        return this.C;
      }
    }, {
      key: "wrapper",
      get: function () {
        return this._;
      }
    }]), t;
  }())();

export default ct;
