import './info.css';
export const infoPopup = () => {
  let template = `<div class="info-popup info-popup-active">
    <div class="icon">
        <div style="cursor : none; pointer-events : none; padding-top:100.000%;position:relative;"><iframe src="https://gifer.com/embed/2GU" width="100%"
                height="100%" style='position:absolute;top:0;left:0;' frameBorder="0" allowFullScreen></iframe>
        </div>
        <div class="center">
        <ion-icon name="reload-circle-outline"></ion-icon>
        </div>
    </div>
    <div class="content">
        <span>We're Sorry, Currently  Updating Our Website</span>
    </div>
    <div class="close">
        <button class="cta-secondary close-trigger">Close</button>
        <button class="cta-primary">Contact Us</button>
    </div>
</div>`;
  const show = () => {
    if ($('body').find('.info-popup').length == 0) {
      $('body').append(template);
      $('.info-popup').addClass('info-popup-active');
      $('.shimmer').show();
    }
  };
  const hide = () => {
    $('.info-popup').removeClass('info-popup-active');
    $('.shimmer').hide();
    setTimeout(function () {
      $('.info-popup').remove();
    }, 500);
  };
  return {
    show,
    hide
  };
};