var width = 100,
  perfData = window.performance.timing,
  // The PerformanceTiming interface represents timing-related performance information for the given page.
  EstimatedTime = -(perfData.loadEventEnd - perfData.navigationStart),
  time = parseInt(EstimatedTime / 1000 % 60) * 100;

// Loadbar Animation
$(".progressor").animate({
  width: width + "%"
}, time);

// Percentage Increment Animation
var PercentageID = $(".progressor"),
  start = 0,
  end = 100,
  durataion = time;
animateValue(PercentageID, start, end, durataion);
function animateValue(id, start, end, duration) {
  var range = end - start,
    current = start,
    increment = end > start ? 1 : -1,
    stepTime = Math.abs(Math.floor(duration / range)),
    obj = $(id);
  var timer = setInterval(function () {
    current += increment;
    //obj.innerHTML = current;
    if (current == end) {
      clearInterval(timer);
    }
  }, stepTime);
}