import Lenis from "../../_snowpack/pkg/@studio-freight/lenis.js";
import anime from "../../_snowpack/pkg/animejs.js";
import gsap from "../../_snowpack/pkg/gsap.js";
import { ScrollTrigger } from "../../_snowpack/pkg/gsap/ScrollTrigger.js";
import { infoPopup } from "../components/info/info.js";
import Footer from "../components/Footer.js";
import Header from "../components/Header.js";
export const basic = () => {
  let isExistFooter = $("body footer");
  if (!isExistFooter.length) {
    $("body").append(Footer());
  }
  let isExistHeader = $("body header");
  if (!isExistHeader.length) {
    $("body").append(Header());
  }
  $("header nav ul li a").click(function () {
    $("header nav ul li a").removeClass("active");
    $(this).addClass("active");
  });
  function triggerPopup(prop) {
    let popup = infoPopup();
    if (prop) {
      popup.show();
    } else {
      popup.hide();
    }
  }
  $('a[href="#"]').click(function (e) {
    e.preventDefault();
    triggerPopup(true);
  });
  $("body").delegate(".close-trigger", "click", function () {
    triggerPopup(false);
  });
  $("html, body").animate({
    scrollTop: 0
  }, 120);
  const lenis = new Lenis({
    lerp: 0.1,
    // smoothTouch: true,
    easing: t => Math.min(1, 1.001 - Math.pow(5, -10 * t)),
    // syncTouch: true,
    touchInertiaMultiplier: 30,
    autoResize: true
  });
  lenis.on("scroll", e => {});
  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }
  requestAnimationFrame(raf);
  const menu = () => {
    const menu = $(".menu");
    const shimmer = $(".shimmer");
    const burger = $(".hangburger");
    const close = menu.find(".upward");
    anime({
      targets: ".menu",
      translateY: -400
    });
    const show = () => {
      let tl = anime.timeline();
      menu.show();
      tl.add({
        targets: ".menu",
        translateY: 0,
        duration: 1000
      }).add({
        targets: ".menu ul li",
        translateX: ["200px", "0px"],
        opacity: [0, 1],
        delay: anime.stagger(100)
      }, "-=900");
      shimmer.fadeIn();
    };
    const hide = () => {
      anime({
        targets: ".menu",
        translateY: -400,
        duration: 1000
      });
      shimmer.fadeOut();
    };
    burger.on("click", () => {
      show();
    });
    close.on("click", () => {
      hide();
    });
    $("a").click(function () {
      hide();
    });
  };
  menu();

  // // chat
  // $('.show-contact').click(function () {

  //     $('.contact-button-sticky-desktop').addClass('contact-button-sticky-desktop-active');
  //     $('.shimmer').show();
  // })
  // $('.contact-button-sticky-desktop .close ion-icon').click(function () {

  //     $('.contact-button-sticky-desktop').removeClass('contact-button-sticky-desktop-active');
  //     $('.shimmer').hide();
  // });

  // scroll logic goes here
  $(window).scroll(() => {
    const logoText = $(".logo p");
    if ($(window).scrollTop() > 200) {
      $("header").addClass("header-active");
      // $('.float-nav').css({ display: 'flex' })
    } else {
      $("header").removeClass("header-active");
      // $('header').show();
      // $('.float-nav').css({ display: 'none' })
    }

    // if ($('main').scrollTop() > 200) {
    //     $('.contact-button-sticky-desktop button').css({ display: 'block' })
    // }

    // else {
    //     $('.contact-button-sticky-desktop button').css({ display: 'none' })
    // }
  });
};